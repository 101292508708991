<template>
  <b-container
    v-if="currentTabIndex === 0"
    class="px-1 py-2"
    fluid
  >
    <b-row class="mb-2">
      <b-col
        cols="12"
      >
        <b-form-group
          label="Search"
          label-for="filter_search"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_search"
            v-model="tableVisit.filter"
            type="text"
            size="sm"
            placeholder="search here"
            debounce="1000"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="d-flex flex-column flex-md-row"
      >
        <b-form-checkbox
          id="opportunity_only"
          v-model="filter.opportunity_only"
          name="opportunity_only"
          value="1"
          unchecked-value="0"
          switch
          @change="onFilterChanged"
        >
          <strong>Show Sales Opportunity Only?</strong>
        </b-form-checkbox>
        <b-form-checkbox
          id="overdue_only"
          v-model="filter.overdue_only"
          name="overdue_only"
          value="1"
          unchecked-value="0"
          switch
          class="mt-2 mt-md-0 ml-0 ml-md-2"
          @change="onFilterChanged"
        >
          <strong>Show Overdue Request Only?</strong>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-table
          ref="table"
          small
          hover
          responsive
          show-empty
          class="mt-2"
          :stacked="isMobile()"
          :per-page="tableVisit.perPage"
          :current-page="tableVisit.currentPage"
          :items="tableVisitProvider"
          :fields="tableVisit.fields"
          :sort-by.sync="tableVisit.sortBy"
          :sort-desc.sync="tableVisit.sortDesc"
          :sort-direction="tableVisit.sortDirection"
          :filter="tableVisit.filter"
          :filter-included-fields="tableVisit.filterOn"
          :tbody-tr-class="tableVisitRequestRowClass"
          :busy="tableVisit.busy"
        >
          <template #cell(index)="data">
            {{ tableVisit.currentPage * tableVisit.perPage - tableVisit.perPage + (data.index + 1) }}
          </template>

          <template #cell(agent_name)="data">
            <div class="text-nowrap">
              {{ `${data.item.agent?.first_name} ${data.item.agent?.last_name}` }}
            </div>
          </template>

          <template #cell(sales_opportunity)="data">
            {{ Number(data.item.sales_opportunity) == 1 ? "Yes" : "No" }}
          </template>

          <template #cell()="data">
            <div class="text-nowrap">
              {{ data.value }}
            </div>
          </template>

          <template #cell(actions)="row">
            <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
              <b-button
                size="sm"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                @click="onShowDetailForm(row.item)"
              >
                View
              </b-button>
              <b-button
                size="sm"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                variant="success"
                @click="onShowLogAddForm(row.item)"
              >
                Log Visit
              </b-button>
              <b-button
                size="sm"
                variant="outline-dark"
                @click="onShowReplyForm(row.item)"
              >
                Send Message
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <div class="w-100 w-sm-25 mb-2 sm-mb-2">
          <b-select
            v-model="tableVisit.perPage"
            :options="tableVisit.pageOptions"
            size="sm"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="tableVisit.currentPage"
          :total-rows="tableVisit.totalRows"
          :per-page="tableVisit.perPage"
          first-number
          last-number
          pills
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-view-detail"
      title="View Details"
      size="lg"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
    >
      <b-tabs>
        <b-tab
          title="Request Details"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Request Date</label>
                <b-input
                  :value="dateTimeFormatter(selected.visit_request?.created_at)"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Number</label>
                <b-input
                  :value="selected.visit_request?.ticket_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Category</label>
                <b-input
                  :value="selected.visit_request?.ticket_category_name"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Due Date</label>
                <b-input
                  :value="selected.visit_request?.due_date"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.visit_request?.customer?.first_name} ${selected.visit_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Mobile Number</label>
                <b-input
                  :value="selected.visit_request?.customer?.mobile_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.visit_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.visit_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Visitation Logs"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-table
                ref="visitationLogTable"
                hover
                responsive
                show-empty
                class="mt-2"
                :stacked="isMobile()"
                :per-page="tableVisitationLog.perPage"
                :current-page="tableVisitationLog.currentPage"
                :items="tableVisitationLogProvider"
                :fields="tableVisitationLog.fields"
                :sort-by.sync="tableVisitationLog.sortBy"
                :sort-desc.sync="tableVisitationLog.sortDesc"
                :sort-direction="tableVisitationLog.sortDirection"
                :filter="tableVisitationLog.filter"
                :filter-included-fields="tableVisitationLog.filterOn"
                :busy="tableVisitationLog.busy"
              >

                <template #cell(index)="data">
                  {{ tableVisitationLog.currentPage * tableVisitationLog.perPage - tableVisitationLog.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(action)="row">
                  <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                    <b-button
                      size="sm"
                      @click="onViewVisitationLog(row.item)"
                    >
                      View
                    </b-button>
                  </div>
                </template>

              </b-table>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableVisitationLog.perPage"
                  :options="tableVisitationLog.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableVisitationLog.currentPage"
                :total-rows="tableVisitationLog.totalRows"
                :per-page="tableVisitationLog.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-log-visit"
      title="Log Visit"
      size="lg"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Number</label>
                <b-input
                  :value="selected.visit_request?.ticket_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Category</label>
                <b-input
                  :value="selected.visit_request?.ticket_category_name"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Due Date</label>
                <b-input
                  :value="selected.visit_request?.due_date"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.visit_request?.customer?.first_name} ${selected.visit_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.visit_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.visit_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="visitation date"
                vid="visitation_date"
                rules="required"
              >
                <div class="form-group">
                  <label for="visitation_date">Visitation Date</label>
                  <b-input
                    id="visitation_date"
                    v-model="visitationLog.visitation_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="purpose"
                vid="purpose"
                rules="required"
              >
                <div class="form-group">
                  <label for="purpose">Purpose</label>
                  <b-form-select
                    id="purpose"
                    v-model="visitationLog.purpose"
                    :options="filteredVisitationPurposes"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Purpose --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:1000"
              >
                <div class="form-group">
                  <label for="remarks">Remarks</label>
                  <b-textarea
                    id="remarks"
                    v-model="visitationLog.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    placeholder="enter remarks here"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <ValidationObserver
        ref="formVisitationLogAttachment"
      >
        <form
          role="form"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="visitation log attachments"
                vid="visitation_attachments"
                rules="required|image|length:1"
              >
                <b-form-group
                  label="Select Photo To Upload"
                  label-for="visitation_attachments"
                  label-class="font-weight-bolder"
                >
                  <b-form-file
                    id="visitation_attachments"
                    v-model="visitationLogAttachment.attachments"
                    multiple
                    accept=".jpg, .jpeg, .png"
                    :disabled="state.busy || visitationLog.visitation_log_attachments.length > 0"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                size="sm"
                class="mr-1"
                variant="success"
                :disabled="visitationLog.visitation_log_attachments.length > 0"
                @click="onValidateUploadVisitaionLogAttachments"
              >
                Upload Photos
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                :disabled="visitationLog.visitation_log_attachments.length === 0"
                @click="onDeleteAllVisitaionLogAttachment"
              >
                Delete Uploaded Photos
              </b-button>
            </b-col>
            <b-col
              cols="12"
              class="mt-3"
            >
              <b-table
                ref="tableVisitationLogAttachments"
                hover
                small
                striped
                responsive
                show-empty
                :stacked="isMobile()"
                :fields="tableVisitationLogAttachments.fields"
                :items="visitationLog.visitation_log_attachments"
              >
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(action)="row">
                  <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                    <b-button
                      size="sm"
                      class="mr-0 mr-md-1 mb-1 mb-md-0"
                      @click="onPreviewVisitationLogAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="onDeleteVisitaionLogAttachment(row.item)"
                    >
                      Delete
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <b-col
        cols="12"
        class="mt-2 mb-2"
      >
        <b-form-checkbox
          id="is_close"
          v-model="visitationLog.is_close"
          name="is_close"
          value="1"
          unchecked-value="0"
          :disabled="state.busy"
          switch
        >
          <strong>CLOSE REQUEST</strong>
        </b-form-checkbox>
      </b-col>

      <b-col
        v-if="parseInt(selected.visit_request?.sales_opportunity) === 0"
        cols="12"
        class="mt-2 mb-2"
      >
        <b-form-checkbox
          id="is_successful"
          v-model="visitationLog.is_successful"
          name="is_successful"
          value="1"
          unchecked-value="0"
          :disabled="(state.busy || parseInt(visitationLog.is_close) === 0)"
          switch
        >
          <strong>SUCCESSFUL RESULT</strong>
        </b-form-checkbox>
      </b-col>

      <b-col
        v-if="parseInt(selected.visit_request?.sales_opportunity) === 1"
        cols="12"
        class="mt-2 mb-2"
      >
        <b-form-checkbox
          id="successful_booking"
          v-model="visitationLog.successful_booking"
          name="successful_booking"
          value="1"
          unchecked-value="0"
          :disabled="state.busy"
          switch
        >
          <strong>SUCCESSFUL BOOKING?</strong>
        </b-form-checkbox>
      </b-col>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Submit
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-visitation-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Visited At</label>
            <b-input
              :value="dateFormatter(selected.log?.visitation_date)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>LGA/RFO Name</label>
            <b-input
              :value="selected.log?.user_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Purpose</label>
            <b-input
              :value="selected.log?.purpose_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <p class="mb-1">
            Uploaded Photos
          </p>
          <b-table
            hover
            small
            striped
            responsive
            show-empty
            :stacked="isMobile()"
            :fields="tableVisitationLogAttachments.fields"
            :items="selected.log?.visitation_log_attachments"
          >
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                <b-button
                  size="sm"
                  @click="onPreviewVisitationLogAttachment(row.item)"
                >
                  Preview
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-attachment"
      :title="ModalAttachmentTitle"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-img
        :src="visitationLogAttachment.preview_photo_path"
        fluid-grow
      />
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-send-reply"
      title="Send Message"
      size="lg"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmSendReply"
    >
      <ValidationObserver ref="formReply">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Recipient</label>
                <b-input
                  :value="selected.visit_request?.agent_name"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="message"
                vid="message"
                rules="required|max:1000"
              >
                <div class="form-group">
                  <label for="remarks">Message</label>
                  <b-textarea
                    id="remarks"
                    v-model="reply.message"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    placeholder="enter message here"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Send Message
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>s

  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, UserVisitRequestService, SharedLogService, UserVisitRequestMessageService, UserAttachmentService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import url from '@/mixins/url'

export default {
  name: 'UserPendingVisits',

  middleware: ['auth', 'user'],

  mixins: [formatter, misc, url],

  props: {
    currentTabIndex: {
      type: Number,
      default: () => 1
    }
  },

  data () {
    return {
      state: {
        busy: false,
        previewing: false
      },
      filter: {
        opportunity_only: 0,
        overdue_only: 0
      },
      selected: {
        visit_request: null,
        log: null
      },
      list: {
        purposes: []
      },
      visitationLog: {
        visit_request: 0,
        customer: 0,
        visitation_date: this.dateFormatter(Date.now()),
        purpose: null,
        remarks: '',
        is_close: 0,
        is_successful: 0,
        successful_booking: 0,
        attachment_path: null,
        visitation_log_attachments: []
      },
      visitationLogAttachment: {
        attachment_path: null,
        preview_photo_name: null,
        preview_photo_path: null,
        attachments: []
      },
      reply: {
        visit_request: 0,
        message: ''
      },
      tableVisit: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 15, key: 'actions', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'status', sortable: true },
          { mobile: 3, key: 'ticket_number' },
          { mobile: 4, key: 'ticket_category_name', label: 'ticket category' },
          { mobile: 5, key: 'due_date' },
          { mobile: 6, key: 'sales_opportunity' },
          { mobile: 7, key: 'customer.first_name', label: 'first name' },
          { mobile: 8, key: 'customer.last_name', label: 'last name' },
          { mobile: 9, key: 'customer.mobile_number', label: 'mobile number' },
          { mobile: 10, key: 'customer.province.province_name', label: 'province' },
          { mobile: 11, key: 'customer.municipality.municipality_name', label: 'municipality' },
          { mobile: 12, key: 'customer.barangay.barangay_name', label: 'barangay' },
          { mobile: 13, key: 'agent_name', label: 'requested by' },
          { mobile: 14, key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      tableVisitationLog: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 6, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'visitation_date', formatter: this.dateFormatter },
          { mobile: 2, key: 'user_name', label: 'lga/rfo' },
          { mobile: 3, key: 'purpose_name', label: 'purpose' },
          { mobile: 4, key: 'created_at', formatter: this.dateTimeFormatter },
          { mobile: 5, key: 'updated_at', formatter: this.dateTimeFormatter }
        ].sort(this.scaleOnMobile)
      },
      tableVisitationLogAttachments: {
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 2, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'file_original_name', label: 'file name' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    filteredVisitationPurposes () {
      const filter = [6]
      return this.list.purposes.filter(item => {
        return filter.includes(item.value)
      })
    },

    ModalAttachmentTitle () {
      return this.visitationLogAttachment.preview_photo_name
    }
  },

  watch: {
    'visitationLog.is_close' (value) {
      if (parseInt(value) === 0) {
        this.visitationLog.is_successful = 0
        this.visitationLog.successful_booking = 0
      }
    }
  },

  mounted () {
    core.index()
    this.getVisitationPurposes()
  },

  methods: {
    tableVisitRequestRowClass (item, type) {
      if (!item || type !== 'row') return
      if (this.dateFormatter(item.due_date) <= this.dateFormatter(Date.now()) && ['Pending', 'Processing'].includes(item.status)) return 'table-danger'
    },

    async tableVisitProvider (ctx) {
      this.tableVisit.busy = true
      return await UserVisitRequestService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          opportunity_only: this.filter.opportunity_only,
          overdue_only: this.filter.overdue_only
        })
      ).then(({ data }) => {
        this.tableVisit.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableVisit.busy = false
      })
    },

    async tableVisitationLogProvider (ctx) {
      this.tableVisitationLog.busy = true
      return await SharedLogService.getVisitationLogs(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          customer_id: this.selected.visit_request.customer?.id,
          request_id: this.selected.visit_request.id
        })
      ).then(({ data }) => {
        this.tableVisitationLog.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableVisitationLog.busy = false
      })
    },

    async getVisitationPurposes () {
      await SharedListService.getVisitationPurposes().then(({ data }) => {
        this.list.purposes = data.map(item => {
          return {
            value: item.id,
            text: item.purpose_name
          }
        })
      })
    },

    onShowDetailForm (current) {
      this.selected.visit_request = { ...current }
      this.$bvModal.show('modal-view-detail')
    },

    onShowLogAddForm (current) {
      this.selected.visit_request = { ...current }
      this.state.previewing = false
      this.visitationLog.visit_request = current.id
      this.visitationLog.customer = current.customer?.id
      this.visitationLog.visitation_date = this.dateFormatter(Date.now())
      this.visitationLog.purpose = null
      this.visitationLog.remarks = ''
      this.visitationLog.is_close = 0
      this.visitationLog.is_successful = 0
      this.visitationLog.successful_booking = 0
      this.visitationLog.attachment_path = null
      this.visitationLog.visitation_log_attachments = []
      this.visitationLogAttachment.attachment_path = null
      this.visitationLogAttachment.preview_photo_name = null
      this.visitationLogAttachment.preview_photo_path = null
      this.visitationLogAttachment.attachments = []
      this.$bvModal.show('modal-log-visit')
    },

    onPreviewVisitationLogAttachment (attachment) {
      this.visitationLogAttachment.preview_photo_name = attachment.file_original_name
      this.visitationLogAttachment.preview_photo_path = this.getImageUrl(
        this.state.previewing ? `${this.selected.log?.attachment_path}/${attachment.file_hash_name}` : `uploads/${this.visitationLog.attachment_path}/${attachment.file_hash_name}`
      )
      this.$bvModal.show('modal-preview-attachment')
    },

    async onValidateUploadVisitaionLogAttachments () {
      await this.$refs.formVisitationLogAttachment.validate().then(isAllFieldsAreValid => {
        if (isAllFieldsAreValid) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to upload photos?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true

              const payload = new FormData()
              this.visitationLogAttachment.attachments.forEach((attachment, index) => {
                payload.append(`attachments[${index}]`, attachment)
              })
              payload.append('attachment_path', this.visitationLogAttachment.attachment_path)

              return new Promise(resolve => {
                UserAttachmentService.uploadLogAttachment(payload).then(({ data }) => {
                  this.state.busy = false

                  this.visitationLogAttachment.attachment_path = this.visitationLog.attachment_path = data.attachment_path
                  this.visitationLog.visitation_log_attachments = data.attachments
                  this.visitationLogAttachment.attachments = []
                  this.$refs.formVisitationLogAttachment.reset()

                  this.$swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    text: 'Photos has been uploaded.',
                    confirmButtonColor: '#06C270',
                    confirmButtonText: 'Dismiss'
                  })
                }).catch(error => {
                  this.state.busy = false
                  if (error instanceof AxiosError) {
                    if (error.code === 422) {
                      resolve(error)
                    }
                  }
                })
              })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        }
      })
    },

    async onDeleteVisitaionLogAttachment (attachment) {
      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Do you really want to delete this photo?',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.state.busy = true
          const payload = {
            attachment_path: this.visitationLog.attachment_path,
            file_hash_name: attachment.file_hash_name
          }
          return new Promise(resolve => {
            UserAttachmentService.deleteLogAttachment(payload).then(({ data }) => {
              this.visitationLog.visitation_log_attachments = this.visitationLog.visitation_log_attachments.filter(attachment => attachment.file_hash_name !== data.file_hash_name)
              this.state.busy = false
              resolve(data)
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
            })
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onDeleteAllVisitaionLogAttachment () {
      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Do you really want to delete uploaded photos?',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.state.busy = true
          const payload = {
            attachment_path: this.visitationLog.attachment_path
          }
          return new Promise(resolve => {
            UserAttachmentService.deleteAllLogAttachment(payload).then(({ data }) => {
              this.state.busy = false
              this.visitationLog.visitation_log_attachments = []
              resolve(data)
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
            })
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to submit log?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserVisitRequestService.post(this.visitationLog).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-log-visit')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            if (parseInt(this.visitationLog.is_close) === 1) {
              this.$refs.table.refresh()
            } else {
              const row = _.find(this.$refs.table.localItems, { id: this.visitationLog.visit_request })
              row.status = data.visit_request.status
              row.updated_at = data.visit_request.updated_at
            }
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onShowReplyForm (current) {
      this.selected.visit_request = { ...current }
      this.reply.visit_request = current.id
      this.reply.message = ''
      this.$bvModal.show('modal-send-reply')
    },

    async onConfirmSendReply (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formReply.validate().then(async success => {
        if (success) {
          const title = 'Do you really want send message?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormReplyPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormReplyPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserVisitRequestMessageService.post(this.reply).then(({ data }) => {
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$bvModal.hide('modal-send-reply')
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onViewVisitationLog (current) {
      this.state.previewing = true
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-visitation-log')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
  <style>
  #modal-preview-attachment .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }
  #modal-preview-attachment .modal-content {
    min-height: 100vh;
  }
  </style>
